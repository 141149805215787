<!--
  功能：引流加群h5
  作者：Vhomelu
  邮箱：vhomelu@xiaoe-tech.com
  时间：2022年02月16日 16:46:44
  版本：v1.0
-->
<template>
    <div class="group-card">
        <div class="group-code">
            <div v-if="isGroupSizeMaximum || isGroupOvertime || isDeleteGroup" class="group-code__error">
                <img
                    src="http://wechatapppro-1252524126.file.myqcloud.com/appzpz3qykw5391/image/787e41e3a5da32ea801e61ac188b0253.png"
                    class="error-icon"
                    alt=""
                />
                <div class="error-tip">
                    <span v-if="isDeleteGroup">当前群聊不存在</span>
                    <span v-else>
                        {{
                            `当前${
                                isGroupSizeMaximum
                                    ? `群人数已到达扫码上限${isGroupOvertime ? '且群二维码过期' : ''}`
                                    : `${isGroupOvertime ? '二维码已过期' : ''} `
                            }`
                        }}
                    </span>
                </div>
            </div>
            <img v-else class="group-code__img" :src="pageConfig.group_code_img" alt="" />

            <div class="group-code__tip" v-if="pageConfig.open_contact_customer === 1">
                若无法扫码进群，请扫码联系客服
                <a href="javascript:void(0)" @click="show = true">点击查看</a>
            </div>
            <div
                class="tip"
                v-else-if="!(isGroupSizeMaximum || isGroupOvertime || isDeleteGroup) && pageConfig.group_code_img"
            >
                如长按无法识别，请截图保存二维码，打开微信扫一扫
            </div>
        </div>
        <van-popup v-model="show" closeable>
            <div class="customer-code">
                <div class="customer-code__header">
                    <h4 class="title">请扫码联系客服进群</h4>
                </div>

                <div class="customer-code__content">
                    <img class="img" :src="pageConfig.supporter_wechat_url" alt />
                    <div class="tip">长按识别二维码，或保存图片</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
const GROUP_STATUS_MAP = {
    NORMAL: 0,
    EXPIRE: 1,
    FULL: 2
};

export default {
    name: 'attractive-group',
    props: {
        pageConfig: {
            type: Object,
            default: () => {}
        },

        //是否需要在组件内上报 群组活码需要上报活码访问次数
        needReportInside: {
            type: Boolean,
            default: false
        },

        //企微外部联系群id
        customerGroupId: {
            type: [Number, null],
            default: null
        }
    },
    data() {
        return {
            show: false
        };
    },

    computed: {
        isGroupSizeMaximum() {
            return this.pageConfig.qr_code_invalid_status === GROUP_STATUS_MAP['FULL'];
        },
        isGroupOvertime() {
            return this.pageConfig.qr_code_invalid_status === GROUP_STATUS_MAP['EXPIRE'];
        },
        // 判断群组活码是否被删除
        isDeleteGroup() {
            return Number(this.pageConfig.delete_state) === 0; // 状态：0-删除,1-正常
        }
    },

    watch: {
        customerGroupId: {
            handler(currentValue) {
                if (currentValue) {
                    if (this.needReportInside && !this.isOpenFirstTime(currentValue)) {
                        const { lead_link_id: group_card_id, app_id } = this.$route.query;
                        this.addView({ group_card_id, app_id });
                    }
                }
            },
            immediate: true
        }
    },

    methods: {
        // 判断用户是否第一次打开这个群名片
        isOpenFirstTime(id) {
            const ids = localStorage.getItem('group_wx_codes_id')
                ? localStorage.getItem('group_wx_codes_id').split(',')
                : [];
            const isOpenFirstTime = [...ids].includes(id.toString());
            if (!ids.includes(id.toString())) {
                ids.push(id.toString());
                localStorage.setItem('group_wx_codes_id', ids);
            }
            return isOpenFirstTime;
        },

        // 上报访问次数
        addView({ app_id, group_card_id }) {
            this.$ajax('add_wx_group_uv', {
                app_id,
                group_card_id,
                group_wx_codes_id: this.customerGroupId
            }).catch((error) => console.error(error));
        }
    }
};
</script>

<style lang="scss" scoped>
.group-card {
    display: flex;
    .group-code {
        padding: 0.32rem 0.5rem;
        width: 100%;
        &__img {
            width: 100%;
        }

        &__tip {
            color: #999;
            text-align: center;
            font-size: 0.2rem;
            margin-top: 40px;
        }

        &__error {
            box-sizing: border-box;
            text-align: center;
            width: 6rem;
            height: 9rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0 auto;
            padding-top: 3.6rem;
            background-color: #fff;
            border-radius: 0.24rem;
            box-shadow: 0 0 0.24rem 0 rgba(51, 51, 51, 0.12);
            background-image: url('http://wechatapppro-1252524126.file.myqcloud.com/appzpz3qykw5391/image/3b178a032ed6ca0599fd9e828ef2517a.jpg');
            background-size: 3.24rem 3.24rem;
            background-repeat: no-repeat;
            background-position: center center;

            .error-icon {
                width: 1.28rem;
            }

            .error-tip {
                font-size: 0.22rem;
                margin: 0.32rem 0;
                text-align: center;
            }
        }

        .tip {
            color: #999;
            font-size: 0.22rem;
            margin: 0.32rem 0;
            text-align: center;
        }
    }

    .customer-code {
        text-align: center;

        &__header {
            text-align: center;

            .title {
                font-size: 0.4rem;
                font-weight: 400;
                margin: 0.32rem 0;
                color: #333;
            }
        }

        &__content {
            .img {
                width: 4.4rem;
                height: 4.4rem;
            }

            .tip {
                margin: 0.32rem 0;
                font-size: 0.28rem;
                color: #666;
            }
        }
    }

    .van-popup {
        width: 5.62rem;
        height: 6.8rem;
        border-radius: 0.16rem;
    }
}
</style>
